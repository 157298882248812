<template>
	<transition :name="transitionName">
		<div class="popPage" v-if="show">
			<div class="popHead bLine">
				<div class="leftBtn iconfont icon-back" @click="()=> $emit('close')"></div>
				<div class="title">
					{{title}}
				</div>
				<div class="rightBtn"></div>
			</div>
			<div class="popCon">
				<slot></slot>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
  props: {
		show: {
			type: Boolean,
			default: false
		},
		transitionName: {
			type: String,
			default: 'page-move'
		},
		title: {
			type: String,
			default: ''
		}
  }
}
</script>
<style lang="less" scoped>
.popPage {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 19;
}
.popHead {
	position: relative;
	height: 48px;
	line-height: 48px;
	text-align: center;
	background-color: #fff;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	z-index: 5;
	color: #333;
	.title {
		font-size: 15px;
		font-weight: bold;
	}
	.icon-back {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 14px;
		font-size: 18px;
	}
	.rightBtn {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0 14px;
		font-size: 15px;
		color: #BBBBBB;
		&.active {
			color: #CAFF41;
		}
	}
}
.popCon {
	padding: 0 15px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: unset;
	height: calc(100% - 48px)
	
}
.pop-up-enter, .pop-up-leave-active {
	transform: translate3d(0, 100%, 0)
}
.pop-up-enter-active, .pop-up-leave-active {
	transition: transform .25s cubic-bezier(0.39,0.575,0.565,1)
} 
.page-move-enter, .page-move-leave-active {
	transform: translate3d(100%, 0, 0)
}
.page-move-enter-active, .page-move-leave-active {
	transition: transform .25s cubic-bezier(0.39, 0.575, 0.565, 1)
} 
</style>
