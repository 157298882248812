<template>
	<transition name="cube-toast-fade">
		<cube-popup type="toast" :z-index="zIndex" :mask="mask" v-show="isVisible" @mask-click="maskClick">
			<div :class="isSquare?'boxLoading':'textLoading'">
				<i v-if="!isLoading && icon" class="iconfont toastIcon" :class="icon"></i>
				<div class="toastIcon" v-if="icon === 'loading'"><cube-primary-loading  :size="35" /></div>
				<div v-show="txt" class="toastText" v-html="txt"></div>
			</div>
		</cube-popup>
	</transition>
</template>
<script type="text/ecmascript-6">
	import CubePopup from '../popup/popup.vue'
	import visibilityMixin from '../../common/mixins/visibility'
	import popupMixin from '../../common/mixins/popup'
	const COMPONENT_NAME = 'cube-toast'
	const EVENT_TIMEOUT = 'timeout'
	export default {
		name: COMPONENT_NAME,
		mixins: [visibilityMixin, popupMixin],
		props: {
			icon: {
				type: String,
				default: null
			},
			mask: {
				type: Boolean,
				default: false
			},
			txt: {
				type: String,
				default: ''
			},
			time: {
				type: Number,
				default: 3000
			},
			zIndex: {
				type: Number,
				default: 900
			}
		},
		computed: {
			isLoading() {
				return this.icon === 'loading'
			},
			isSquare() {
				if (this.icon || this.isLoading) {
					return true
				} else {
					return false
				}
			}
		},
		methods: {
			maskClick() {
				this.maskClosable && this.hide()
			},
			show() {
				this.isVisible = true
				this.clearTimer()
				this.$nextTick(() => {
					if (this.time !== 0) {
						this.timer = setTimeout(() => {
							this.hide()
							this.$emit(EVENT_TIMEOUT)
						}, this.time)
					}
				})
			},
			hide() {
				this.isVisible = false
				this.clearTimer()
			},
			clearTimer() {
				clearTimeout(this.timer)
				this.timer = null
			}
		},
		components: {
			CubePopup
		}
	}
</script>
<style lang="stylus" rel="stylesheet/stylus">
@require "../../common/stylus/variable.styl"
.cube-toast
	&.cube-popup
		z-index: 900
.textLoading
	align-items: center
	color: $toast-color
	background-color: $toast-bgc
	border-radius: 12px
	display: flex
	justify-content: center
	min-width: 152px
	max-width: 226px
	padding: 12px
.boxLoading
	width: 136px
	height: 136px
	overflow: hidden
	display: flex
	color: $toast-color
	background-color: $toast-bgc
	flex-direction: column
	border-radius: 12px
	align-items: center
.toastIcon
	width: 50px
	height: 50px
	font-size: 50px
	display: flex
	align-items: center
	justify-content: center
	margin-top: 23px
	margin-bottom: 11px
	color: #fff

.toastText
	font-size: 13px
	display: flex
	flex-wrap: wrap
	line-height: 1.4
.cube-toast-fade-enter-active
	animation: toastIn .2s
.cube-toast-fade-leave-active
	animation: toastOut .2s

@keyframes toastIn
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes toastOut
	0%
		opacity: 1
	100%
		opacity: 0
</style>
