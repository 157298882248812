import VueRouter from 'vue-router'
import routes from './routes'
import { visitorLogin, personLogin } from '@/api/index'

import Vue from 'vue'
import toast from '../cubeUi/components/toast/toast.vue'
import { createAPI } from 'cube-ui'
createAPI(Vue, toast, true)

const router = new VueRouter({
	mode: "history",
  routes: routes
})
export default router
// router.beforeEach((to, from, next) => {
// 	let wechartId = window.localStorage.getItem('WECHART_ID')
// 	const uri = decodeURIComponent(window.location.href)
// 	// console.log(wechartId, uri)
// 	const state = new Date().getTime()
// 	if (!wechartId) {
// 		// window.location.href = ``
		
// 		wechartId = 'GoodLuck2024'
// 		window.localStorage.setItem('WECHART_ID', wechartId)
// 	}
// 	// 访客业务
// 	if(to.meta.type == 'notLogin') {
// 		next(true)
// 	} if (to.meta.type == 'visitor') {
// 		let visiterInfo = window.localStorage.getItem('USER_INFO_VISITOR')
// 		if (!visiterInfo) {
// 			visitorLogin({webchartId: wechartId}).then(res=> {
// 				if (res.result) {
// 					window.localStorage.setItem('USER_INFO_VISITOR', res.result)
// 				}
// 				next(true)
// 			}).catch(err=> {
// 				next(false)
// 			})
// 		}
// 	} else { // 员工业务
// 		let personInfo = window.localStorage.getItem('USER_INFO_PERSON')
// 		personLogin({webchartId: wechartId}).then(res=> {
// 			if (res.result) {
// 				window.localStorage.setItem('USER_INFO_PERSON', JSON.stringify(res.result))
// 				next(true)
// 			} else {
// 				next('/staffValid')
// 			}				
// 		}).catch(err=> {
// 			next(false)
// 		})
// 	}
// })