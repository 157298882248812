import Vue from 'vue'
import VueRouter from 'vue-router'
import Cube from './cubeUi/index'
import App from './App.vue'
import router from './router'

import 'amfe-flexible'
import FastClick from 'fastclick'
import CubePage from '@/components/cube-page'
import Bus from "./utils/bus"

// import vConsole from 'vconsole'
// FastClick.attach(document.body)
Vue.use(Cube)
Vue.use(VueRouter)
Vue.use(Bus)
const setHtmlTitle = (title)=> {
	document.getElementsByTagName("title")[0].innerText = title
}
const clearTimer = (key)=> {
	if(!window[key]){ window[key] = []}
	window[key].map(item=>{
		clearInterval(item)
	})
}
Vue.prototype.$clearTimer = clearTimer
Vue.prototype.$setHtmlTitle = setHtmlTitle
// Vue.use(vConsole)
Vue.component('CubePage', CubePage)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
