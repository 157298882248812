<template>
	<div  class="pageMain">
		<div class="form"> 
			<div class="formTitle">{{lange == 'CN'? '您的个人信息': 'Your Personal Information'}}</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label required">{{lange == 'CN'? '手机号': 'Mobile Phone'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.phone" clearable disabled :placeholder="lange == 'CN'? '请输入手机号码': 'Mobile Phone'"/>
				</div>
			</div>
			
			<div class="formLine bLine" v-if="lange == 'EN'">
				<div class="label required">{{lange == 'CN'? '电子邮箱': 'Email'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.email" clearable disabled :options="cardOptions" :placeholder="lange == 'CN'? '电子邮箱': 'Email'"/>
				</div>
			</div>
			
			<div class="formLine bLine" v-if="lange == 'EN'">
				<div class="label required">{{lange == 'CN'? '手机号': 'Mobile Phone'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.phone" clearable disabled :placeholder="lange == 'CN'? '请输入手机号码': 'Mobile Phone'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '姓名': 'Name'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.userName" clearable disabled :placeholder="lange == 'CN'? '姓名': 'Name'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '公司': 'Company'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.companyName" clearable disabled :placeholder="lange == 'CN'? '公司': 'Company'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '职位': 'Position'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.positionName" clearable disabled :placeholder="lange == 'CN'? '职位': 'Position'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '国家/地区': 'Country/Region'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.countryName" clearable disabled :placeholder="lange == 'CN'? '国家/地区': 'Country/Region'"/>
				</div>
			</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label required">{{lange == 'CN'? '省份': 'Province'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.provinceName" disabled clearable :placeholder="lange == 'CN'? '省份': 'Province'"/>

				</div>
			</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label required">{{lange == 'CN'? '城市': 'City'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.cityName" clearable disabled :placeholder="lange == 'CN'? '城市': 'City'"/>

				</div>
			</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label ">{{lange == 'CN'? '电子邮箱': 'Email'}}</div>
				<div class="input">
					<cube-input v-model="userInfo.email" clearable disabled :options="cardOptions" :placeholder="lange == 'CN'? '电子邮箱': 'Email'"/>
				</div>
			</div>
			<template v-if="lange == 'CN'">
			<div class="formTitle"><span style="color: #f00">*</span>1.公司类别(单选)</div>
			<div class="formLine" v-for="(item, index) in ask1" :key="index + 'gs'">
				<div class="checkInput" :class="{active: item.checked}">
					<div class="checked">
						<i class="iconfont icon-duihao"/>
					</div>
					{{item.text}}
				</div>
			</div>
			<div class="formTitle"><span style="color: #f00">*</span>2.您感兴趣的产品类型 (单选)</div>
			<div class="formLine" v-for="(item, index) in ask2" :key="index + 'xq'">
				<div class="checkInput" :class="{active: item.checked}">
					<div class="checked">
						<i class="iconfont icon-duihao"/>
					</div>
					{{item.text}}
				</div>
			</div>
			<div class="formTitle"><span style="color: #f00">*</span>3.您参加展会的目的 (多选)</div>
			<div class="formLine" v-for="(item, index) in ask3" :key="index  + 'md'">
				<div class="checkInput" :class="{active: item.checked}">
					<div class="checked more">
						<i class="iconfont icon-duihao"/>
					</div>
					{{item.text}}
				</div>
			</div>
			</template>
		</div>
	</div>
</template>
<script>
import { isIdCard, parseTime, isPhone, addZero } from '@/utils'
import { countryList } from '@/utils/countryData'
import { provinceData } from '@/utils/province'
import { add, upLoadUser} from '@/api'
export default {
	props: {
		userInfo: {
			type: Object,
			default: ()=> {
				return {}
			}
		}
	},
	data() {
		return {
			lange: window.localStorage.getItem('LANGE') || 'CN',
			reasonOptions: [],
			cardOptions: [],
			countryList: countryList,
			sexOptions: [
				{text: '男', value: '1'},
				{text: '女', value: '2'},
			],
			ask1: [
				{text: '品牌商', textEn: '', value: '1', checked: false},
				{text: '生产商', textEn: '',  value: '2', checked: false},
				{text: '代理商/分销商/批发商', textEn: '',  value: '3', checked: false},
				{text: '零售商', textEn: '',  value: '4', checked: false},
				{text: '原材料/生产设备', textEn: '',  value: '5', checked: false},
				{text: '院校/科研单位', textEn: '',  value: '6', checked: false},
				{text: '其他:', textEn: '',  value: '7', checked: false},
			],
			ask2: [
				{text: '幼教产品', textEn: '',  value: '1', checked: false},
				{text: '玩具', textEn: '',  value: '2', checked: false},
				{text: '游乐游艺', textEn: '',  value: '3', checked: false},
				{text: '配件及机械设备', value: '4', checked: false},
				{text: '其他:', textEn: '',  value: '5', checked: false},
			],
			ask3: [
				{text: '寻找供应商/新货源及订货', textEn: '',  value: '1', checked: false},
				{text: '市场调研/收集产品资料', textEn: '',  value: '2', checked: false},
				{text: '拜访客户', textEn: '',  value: '3', checked: false},
				{text: '参加会议/论坛活动', value: '4', checked: false},
				{text: '其他:', textEn: '',  value: '5', checked: false},
			],
			provinceList: provinceData,
			cityList: []
		}
	},
	computed: {
		disabled() {
			return false
		}
	},
	mounted() {
		this.initChecked()
	},
	methods: {
		initChecked() {
			if (this.userInfo.companyType) {
				this.ask1.forEach(e=> {
					if (e.text == this.userInfo.companyType) {
						e.checked = true
					}
				})
			}
			if (this.userInfo.productType) {
				this.ask2.forEach(e=> {
					if (e.text == this.userInfo.productType) {
						e.checked = true
					}
				})
			}
			if (this.userInfo.objective) {
				let onjectArr = this.userInfo.objective.split(',')
				this.ask3.forEach(e=> {
					if (onjectArr.indexOf(e.text) != -1) {
						e.checked = true
					}
				})
			}
		}
	}
}
</script>
<style lang="less" scoped>
.checkInput {
	display: flex;
	color: #c5c4ca;
	align-items: center;
	margin-top: 5px;
	&.active {
		color: #333;
		font-weight: bold;
		.checked {
			background-color: #fabf00;
			color: #fff;
			border: 1px solid #fabf00;
			&.more {
				background-color: #fabf00;
				color: #fff;
				border: 1px solid #fabf00;
			}
			> i {
				margin-top: 1px;
				font-size: 18px;
			}
		}
	}
	.checked {
		width: 21px;
		height: 20px;
		border: 1px solid #c5c4ca;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		transition: all 0.3s;
		border-radius: 100px;
		margin-right: 10px;
		&.more {
			border-radius: 2px;
			width: 18px;
			height: 17px;
		}
	}
}
.pageMain {
	padding: 15px;
}
.formTitle {
	font-size: 15px;
	font-weight: bold;
	color: #333;
	margin-top: 15px;
	padding-bottom: 5px;
}
.formLine {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 3px 0;
	.label {
		width: 100px;
		color: #333;
		padding-left: 2px;
		padding-top: 10px;
	}
	.input {
		flex: 1;
		width: 100%;
	}
}
.btnLine {
	padding-top: 20px;
	padding-bottom: 30px;
	.submitBtn {
		border-radius: 1000px;
		font-weight: bold;
		box-shadow: 0px 10px 10px rgba(250, 193, 0, 0.2);
	}
}
</style>
