<template>
	<div class="visitorBook" v-if="show">
		<div class="bookCon">
			<div class="bookMain">
				<div class="bookTitle bLine">
					2024教玩具展-隐私条款
					<i class="iconfont icon-close" @click="$emit('close')"/>
				</div>
				<div style="overflow: scroll; height: calc(100vh - 230px)">
					<p>参观人士条例及规则：</p>
					<p>1.	主办方十分重视个人隐私，将遵守适用的个人资料保护法律，包括有关个人资料保护的适用中国法律法规。</p>
					<p>信息收集:</p>
					<p>为向参观人士提供更好的服务并满足以下目的，主办方可以在参观人士登记或告知某些信息时收集该信息。在此过程中，主办方还可收集公司的个人联络人或公司雇员的个人信息。如登记表中所载，部分要求提供的信息是必要性的，部分则由参观人士自愿提供。主办方仅出于下段所载目的保留个人资料。</p>
					<p>信息的使用: 主办方可将信息用于以下目的：</p>
					<p>1.	处理主办方展览会展览或参观申请；</p>
					<p>2. 通过将个人资料纳入其数据库促进并改善其运营，并根据资料进行分析、研究和审计；</p>
					<p>3.	向参观人士推广并告知相关活动或展览会；</p>
					<p>4.	向其公司发送出版物和研究资料；</p>
					<p>5.	主办方可将参观人士的个人资料（包括其姓名、电子邮箱及实际地址）用于为组委会的活动或展览会的宣传和邀请，以及通过传真、电邮、直邮、电话及其他通讯方式开展的服务推广或向参观人士发送电子通讯。</p>
					<p>6.	本人同意在展览会中选择的服务商对本人的入场证进行扫描，并愿意向其提供个人数据。本人同意前述个人数据可用于参展商及赞助商就其产品或服务与本人进行联络，并同意参展商及赞助商为上述目的而将数据转移至中国以外的地区。同时，为经营展览会、分析访客流量及改善访客的活动体验所需，本人理解并同意由主办方和任何代表其利益的第三方持有并使用本人的个人数据。</p>
					<p>数据披露/传输</p>
					<p>1.	主办方所持有的个人资料保密，但可以将个人资料提供或传输；</p>
					<p>2. 与主办方业务运作或服务有关的代理、顾问、核数师、承办商或服务提供商，为促进及加强主办方的运作，将所收集的个人资料作分析、研究及审计；</p>
					<p>参观人士同意和权利</p>
					<p>1.	参观人士签署本合同，即表示明确同意本条所载的对其个人资料的收集、使用和传输，包括为直接促销而使用并提供其个人资料；</p>
					<p>2. 参观人士可通过官网、固话，要求主办方更正其错误的个人资料；</p>
					<p>3.	我已知晓主办方可能会在展会现场拍摄图片或视频等， 同意主办方将含有本人形象的照片或视频用于展会宣传之用途。</p>
					<p>4.	本合约受中国的法律管辖。如果双方就本合约或展览会有任可争议或分歧，任何一方可将该争议提交主办方所在地有关法院进行审理。</p>
				</div>
			</div>
			<div class="footerCon">
				<cube-button  class="submitBtn" @click="$emit('confirm')">确认申请</cube-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			checked: false,
			nums: 15,
			timer: null
		}
	},
	watch: {
		nums(val) {
			if (val == 0) {
				clearInterval(this.timer)
				this.timer = null
			}
		}
	},
  destroyed() {
  	clearInterval(this.timer)
		this.timer = null
  },
	methods: {
		check() {
			if (this.nums != 0) {
				return
			}
			this.checked = !this.checked
		}
	}
}
</script>
<style lang="less" scoped>
.visitorBook {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5);
	.bookCon {
		position: absolute;
		top: 5%;
		left: 15px;
		width: calc(100% - 30px);
		height: 90%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.bookMain {
			background-color: #fff;
			flex: 1;
			overflow: hidden;
			border-radius: 10px;
		}
		.bookTitle {
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			
			font-size: 15px;
			color: #333;
			position: relative;
			.icon-close {
				position: absolute;
				right: 10px;
				top: 10px;
				font-size: 22px;
				color: #333;
			}
		}
		.bookScrll {
			flex: 1;
			overflow: scroll;
		}
		p {
			color: #666;
			margin: 10px;
			line-height: 1.5;
		}
	}
}
.submitBtn {
	border-radius: 100px;
}
.footerCon {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}
</style>