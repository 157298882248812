<template>
	<div class="weui-primary-loading" :style="{ fontSize: size + 'px'}">
		<span class="weui-primary-loading__dot"></span>
	</div>
</template>
<script type="text/ecmascript-6">
  export default {
    name: 'cube-primary-loading',
    props: {
      size: {
        type: Number,
				default: 16
      }
    }
  }
</script>
<style lang="stylus" rel="stylesheet/stylus">
.weui-primary-loading
	display: inline-flex
	position: relative
	width: 1em
	height: 1em
	vertical-align: middle
	color: #ededed
	animation: circleLoading 1s steps(60, end) infinite
.weui-primary-loading:before,
.weui-primary-loading:after
	content: ""
	display: block
	width: 0.5em
	height: 1em
	box-sizing: border-box
	border: 0.125em solid
	border-color: #ededed
.weui-primary-loading:before
	border-right-width: 0
	border-top-left-radius: 1em
	border-bottom-left-radius: 1em
	-webkit-mask-image: linear-gradient(180deg, #000000 8%, rgba(0, 0, 0, 0.3) 95%)

.weui-primary-loading:after 
	border-left-width: 0
	border-top-right-radius: 1em
	border-bottom-right-radius: 1em
	-webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 8%, rgba(0, 0, 0, 0.3) 95%)
.weui-primary-loading__dot 
	position: absolute
	top: 0
	left: 50%
	margin-left: -0.0625em
	width: 0.125em
	height: 0.125em
	border-top-right-radius: 0.125em
	border-bottom-right-radius: 0.125em
	background: currentColor
@keyframes circleLoading
	0%
		transform: rotate3d(0, 0, 1, 0deg)
	100%
		transform: rotate3d(0, 0, 1, 360deg)
.weui-primary-loading_brand 
	color: #ededed
.weui-primary-loading_transparent
	color: #ededed
</style>
