export const cn = {
	bg: require('@/assets/images/bannerCn.jpg'),
	btn1: '立刻登记',
	btn2: '我已登记,打开回执',
	share: '分享'
}

export const en = {
	bg: require('@/assets/images/bannerEn.jpg'),
	btn1: 'Register Now',
	btn2: 'Registered Visitor',
	share: 'Share'
}