import Regist from '@/views/Regist'
import Home from '@/views/home'
import Login from '@/views/Login'
import Confirmation from '@/views/Confirmation'
const routes = [
	{
	  path: '/index',
	  name: 'index',
	  component: Home,
		meta: { type: 'notLogin' }
	},
	{
	  path: '/regist',
	  name: 'Regist',
	  component: Regist,
		meta: { type: 'visitor' }
	},
	{
	  path: '/login',
	  name: 'Login',
	  component: Login,
		meta: { type: 'notLogin' }
	},
	{
	  path: '/confirmation',
	  name: 'Confirmation',
	  component: Confirmation,
		meta: { type: 'notLogin' }
	},
	
]

export default routes
