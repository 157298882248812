import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import toast from '../cubeUi/components/toast/toast.vue'
import { createAPI } from 'cube-ui'
createAPI(Vue, toast, true)
let requestHead = JSON.parse(window.localStorage.getItem('REQUEST_HEAD')) || {}
const instance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
		'Authheader': ''
  }
})
// 请求拦截器
instance.interceptors.request.use(
  config => {
		config.headers.token = window.localStorage.getItem('AUTH_TOKEN')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  response => {
		const { data } = response
		
    if (data.code === 200 || data.code === 0 || data.code === 404) {
      return data
    } else {
			if (data.code === 10038 || data.msg === "Token has expired") {
				toast.$create({
					txt: 'Refresh token error',
					icon:'icon-shibai',
					time: 3000
				}).show()
			} else {
				
				if (response.config.url.indexOf('queryByPhone') != -1) {
					console.log('系统里没有资料')
				} else {
					toast.$create({
						txt: data.message,
						time: 3000
					}).show()
					return Promise.reject(data)
				}
			}
    }
  },
  error => {
		const { message } = error
		
		if (message == '未找到对数据') {
			return Promise.reject(error)
		} else {
			toast.$create({
				txt: message,
				time: 3000
			}).show()
			return Promise.reject(error)
		}
  }
)

export default instance
