<template>
<cube-page title="来访申请"> 
	<div slot="content" class="pageMain">
		<div class="form"> 
			<div class="formTitle">{{lange == 'CN'? '您的个人信息': 'Your Personal Information'}}</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label required">{{lange == 'CN'? '手机号': 'Mobile Phone'}}</div>
				<div class="input">
					<cube-input v-model="form.phone" clearable disabled :placeholder="lange == 'CN'? '请输入手机号码': 'Mobile Phone'"/>
				</div>
			</div>
			
			<div class="formLine bLine" v-if="lange == 'EN'">
				<div class="label required">{{lange == 'CN'? '电子邮箱': 'Email'}}</div>
				<div class="input">
					<cube-input v-model="form.email" clearable :options="cardOptions" :placeholder="lange == 'CN'? '电子邮箱': 'Email'"/>
				</div>
			</div>
			
			<div class="formLine bLine" v-if="lange == 'EN'">
				<div class="label required">{{lange == 'CN'? '手机号': 'Mobile Phone'}}</div>
				<div class="input">
					<cube-input v-model="form.phone" clearable :placeholder="lange == 'CN'? '请输入手机号码': 'Mobile Phone'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '姓名': 'Name'}}</div>
				<div class="input">
					<cube-input v-model="form.userName" clearable :placeholder="lange == 'CN'? '姓名': 'Name'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '公司': 'Company'}}</div>
				<div class="input">
					<cube-input v-model="form.companyName" clearable :placeholder="lange == 'CN'? '公司': 'Company'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '职位': 'Position'}}</div>
				<div class="input">
					<cube-input v-model="form.positionName" clearable :placeholder="lange == 'CN'? '职位': 'Position'"/>
				</div>
			</div>
			<div class="formLine bLine">
				<div class="label required">{{lange == 'CN'? '国家/地区': 'Country/Region'}}</div>
				<div class="input">
					<cube-select v-model="form.countryName" clearable :options="countryList" :placeholder="lange == 'CN'? '国家/地区': 'Country/Region'"/>
				</div>
			</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label required">{{lange == 'CN'? '省份': 'Province'}}</div>
				<div class="input">
					<cube-select v-model="form.provinceName" 
					             clearable
											 @change="provinceChange"
										   :options="provinceList" 
											 :placeholder="lange == 'CN'? '省份': 'Province'"/>
				</div>
			</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label required">{{lange == 'CN'? '城市': 'City'}}</div>
				<div class="input">
					<cube-select v-model="form.cityName" 
											 clearable 
											 :options="cityList" 
											 :placeholder="lange == 'CN'? '城市': 'City'"/>
				</div>
			</div>
			<div class="formLine bLine" v-if="lange == 'CN'">
				<div class="label ">{{lange == 'CN'? '电子邮箱': 'Email'}}</div>
				<div class="input">
					<cube-input v-model="form.email" clearable :options="cardOptions" :placeholder="lange == 'CN'? '电子邮箱': 'Email'"/>
				</div>
			</div>
			<template v-if="lange == 'CN'">
			<div class="formTitle"><span style="color: #f00">*</span>1.公司类别(单选)</div>
			<div class="formLine" v-for="(item, index) in ask1" :key="index + 'gs'">
				<div class="checkInput" :class="{active: item.checked}" @click="changeAsk1(item, index, ask1)">
					<div class="checked">
						<i class="iconfont icon-duihao"/>
					</div>
					{{item.text}}
					<cube-input style="border-bottom: 1px solid #eee;" v-if="item.text == '其他:'" v-model="item.textMore" clearable placeholder="请输入"/>
				</div>
			</div>
			<div class="formTitle"><span style="color: #f00">*</span>2.您感兴趣的产品类型 (单选)</div>
			<div class="formLine" v-for="(item, index) in ask2" :key="index + 'xq'">
				<div class="checkInput" :class="{active: item.checked}" @click="changeAsk1(item, index, ask2)">
					<div class="checked">
						<i class="iconfont icon-duihao"/>
					</div>
					{{ item.text }}
					<cube-input style="border-bottom: 1px solid #eee;" v-if="item.text == '其他:'" v-model="item.textMore" clearable placeholder="请输入"/>
				</div>
			</div>
			<div class="formTitle"><span style="color: #f00">*</span>3.您参加展会的目的 (多选)</div>
			<div class="formLine" v-for="(item, index) in ask3" :key="index  + 'md'">
				<div class="checkInput" :class="{active: item.checked}">
					<div class="checked more" @click="changeAskMore(item)">
						<i class="iconfont icon-duihao"/>
					</div>
					{{item.text}}
					<cube-input style="border-bottom: 1px solid #eee;" v-if="item.text == '其他:'" v-model="item.textMore" clearable placeholder="请输入"/>
				</div>
			</div>
			</template>
		</div>
		<div class="btnLine">
			<cube-button :disabled="disabled" class="submitBtn" @click="submit">{{lange == 'CN' ? '确认提交':'Submit'}}</cube-button>
		</div>
	</div>
</cube-page>
</template>
<script>
import { isIdCard, parseTime, isPhone, addZero } from '@/utils'
import { countryEn } from '@/utils/countryData'
import { countryCn } from '@/utils/countryCn'
import { provinceData } from '@/utils/province'
import { add, upLoadUser} from '@/api'
export default {
	data() {
		return {
			form: {
				phone: window.localStorage.getItem('USER_PHONE'),
				companyType: '',
				productType: '',
				objective: '',
				email: window.localStorage.getItem('USER_EMAIL')
			},
			lange: window.localStorage.getItem('LANGE') || 'CN',
			reasonOptions: [],
			cardOptions: [],
			
			sexOptions: [
				{text: '男', value: '1'},
				{text: '女', value: '2'},
			],
			ask1: [
				{text: '品牌商', textEn: '', value: '1', checked: false},
				{text: '生产商', textEn: '',  value: '2', checked: false},
				{text: '代理商/分销商/批发商', textEn: '',  value: '3', checked: false},
				{text: '零售商', textEn: '',  value: '4', checked: false},
				{text: '原材料/生产设备', textEn: '',  value: '5', checked: false},
				{text: '院校/科研单位', textEn: '',  value: '6', checked: false},
				{text: '集成商', textEn: '',  value: '7', checked: false},
				{text: '幼儿园', textEn: '',  value: '8', checked: false},
				{text: '文旅地产', textEn: '',  value: '8', checked: false},
				{text: '其他:', textEn: '',  value: '9', checked: false},
			],
			ask2: [
				{text: '教具', textEn: '',  value: '3', checked: false},
				{text: '玩具', textEn: '',  value: '2', checked: false},
				{text: '游乐游艺', textEn: '',  value: '3', checked: false},
				{text: '配件及机械设备', value: '4', checked: false},
				{text: '其他:', textEn: '',  value: '5', checked: false},
			],
			ask3: [
				{text: '寻找供应商/新货源及订货', textEn: '',  value: '1', checked: false},
				{text: '市场调研/收集产品资料', textEn: '',  value: '2', checked: false},
				{text: '拜访客户', textEn: '',  value: '3', checked: false},
				{text: '参加会议/论坛活动', value: '4', checked: false},
				{text: '其他:', textEn: '',  value: '5', checked: false},
			],
			provinceList: provinceData,
			cityList: []
		}
	},
	computed: {
		disabled() {
			return false
		},
		countryList () {
			if (this.lange == 'CN') {
				return countryCn
			} else {
				return countryEn
			}
		}
	},
	methods: {
		provinceChange(e, i) {
			this.cityList = this.provinceList[i].children
		},
		submit() {
			let required = []
			if (this.lange == 'CN') {
				required = [
					{name: 'phone', text: '手机号'}, 
					{name: 'userName', text: '姓名'},
					{name: 'positionName', text: '职位'},
					{name: 'countryName', text: '国家/地区'},
					{name: 'provinceName', text: '省份'},
					{name: 'cityName', text: '城市'},
				]
			} else {
				required = [
				{name: 'email', text: 'Email'},
				{name: 'userName', text: 'Name'},
				{name: 'positionName', text: 'Position'},
				{name: 'countryName', text: 'Country/Region'},]
			}
			try {
				required.forEach(e=> {
					if (!this.form[e.name]) {
						let text = this.lange == 'CN' ? `请输入${e.text}` : `${e.text} required`
						this.$createToast({ txt: text, icon: 'iconfont icon-error', time: 2000 }).show()
						throw new Error(e.text)
					}
				})
			}catch(e){
				return
			}
			if (this.lange == 'CN') {
				this.form.companyType = this.ask1.filter(e=> {
					return e.checked
				}).map(e=> {
					let str = `${e.text}${e.textMore ? e.textMore : ''}`
					return str 
				}).toString()
				this.form.productType = this.ask2.filter(e=> {
					return e.checked
				}).map(e=> {
					let str = `${e.text}${e.textMore ? e.textMore : ''}`
					return str 
				}).toString()
				this.form.objective = this.ask3.filter(e=> {
					return e.checked
				}).map(e=> {
					let str = `${e.text}${e.textMore ? e.textMore : ''}`
					return str 
				}).join(',')
				if (!this.form.companyType) {
					this.$createToast({ txt: '请选择公司类别', icon: 'iconfont icon-error', time: 2000 }).show()
					return
				}
				if (!this.form.productType) {
					this.$createToast({ txt: '请选择感兴趣的产品类型', icon: 'iconfont icon-error', time: 2000 }).show()
					return
				}
				if (!this.form.objective) {
					this.$createToast({ txt: '请选择您参加展会的目的', icon: 'iconfont icon-error', time: 2000 }).show()
					return
				}
			}

			const loading = this.$createToast({ txt: '请等待...', icon: 'loading', time: 0 })
			loading.show()
			add(this.form).then(res=> {
				this.$createToast({ txt: '请等待...', icon: 'iconfont icon-success', time: 2000 }).show()
				window.localStorage.setItem('TOY_USER_INFO', JSON.stringify(res.result))
				if (this.lange == 'CN') {
					window.localStorage.setItem('TOY_PHONE', this.form.phone)
				} else {
					window.localStorage.setItem('TOY_EMAIL', this.form.email)
				}
				this.$router.push('/confirmation')
			}).finally(() => {
        loading.hide()
      }).catch(err=> {
				if (err.message.indexOf('手机号码已经注册') != -1) {
					if (this.lange == 'CN') {
						window.localStorage.setItem('TOY_PHONE', this.form.phone)
					} else {
						window.localStorage.setItem('TOY_EMAIL', this.form.email)
					}
					this.$router.push('/')
				}
			})
		},
		changeAsk1(item, index, list) {
			list.forEach(e=> {
				e.checked = false
			})
			item.checked = true
		},
		changeAskMore(item) {
			item.checked = !item.checked
		}
	}
}
</script>
<style lang="less" scoped>
.checkInput {
	display: flex;
	color: #c5c4ca;
	align-items: center;
	margin-top: 5px;
	&.active {
		color: #333;
		font-weight: bold;
		.checked {
			background-color: #fabf00;
			color: #fff;
			border: 1px solid #fabf00;
			&.more {
				background-color: #fabf00;
				color: #fff;
				border: 1px solid #fabf00;
			}
			> i {
				margin-top: 1px;
				font-size: 18px;
			}
		}
	}
	.checked {
		width: 21px;
		height: 20px;
		border: 1px solid #c5c4ca;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		transition: all 0.3s;
		border-radius: 100px;
		margin-right: 10px;
		&.more {
			border-radius: 2px;
			width: 18px;
			height: 17px;
		}
	}
}
.pageMain {
	padding: 15px;
}
.formTitle {
	font-size: 15px;
	font-weight: bold;
	color: #333;
	margin-top: 15px;
	padding-bottom: 5px;
}
.formLine {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 3px 0;
	.label {
		width: 100px;
		color: #333;
		padding-left: 2px;
		padding-top: 10px;
	}
	.input {
		flex: 1;
		width: 100%;
	}
}
.btnLine {
	padding-top: 20px;
	padding-bottom: 30px;
	.submitBtn {
		border-radius: 1000px;
		font-weight: bold;
		box-shadow: 0px 10px 10px rgba(250, 193, 0, 0.2);
	}
}
</style>
