<template>
  <div id="app" :class="{isPc: isPc}">
    <Home/>
		<cube-view></cube-view>
  </div>
</template>
<script type="text/ecmascript-6">
function isPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  var flag = true
  for (var i = 0; i < Agents.length; i++) {
    if (userAgentInfo.indexOf(Agents[i]) > 0) {
      flag = false
      break
    }
  }
  return flag
}
const isPc = isPC()
if (isPc) {
	setTimeout(()=> {
		document.documentElement.style.fontSize = '54px'
	}, 100)
}
import Home from '@/views/home'
import cubeView from '@/components/cube-view'
export default {
  components: { Home, cubeView },
	data() {
		return { 
			isPc: isPC()
		}
	},
	created() {
		window.localStorage.setItem('LANGE', 'CN')
	}
}
</script>
<style lang="less">
@import "~@/assets/iconfont/iconfont.css";
@import "~@/assets/css/public.less";
html, body, #app {
	margin: 0 auto;
	position: relative;
	height: 100%;
	font-size: 14px;
	color: #efefef;
	background-color: #f5f8ff;
}
.isPc {
	max-width: 460PX;
}
</style>
