<template>
	<div class="scrollClock">
		<div class="column" :style="{transform: `translateY(${-lineHeight*index6}PX)`}">
			<div class="num" v-for="(item, index) in arr6" :key="index">{{ item }}</div>
		</div>
		<div class="column" :style="{transform: `translateY(${-lineHeight*index5}PX)`}">
			<div class="num" v-for="(item, index) in arr5" :key="index">{{ item }}</div>
		</div>
		<div>:</div>
		<div class="column" :style="{transform: `translateY(${-lineHeight*index4}PX)`}">
			<div class="num" v-for="(item, index) in arr4" :key="index">{{ item }}</div>
		</div>
		<div class="column" :style="{transform: `translateY(${-lineHeight*index3}PX)`}">
			<div class="num" v-for="(item, index) in arr3" :key="index">{{ item }}</div>
		</div>
		<div>:</div>
		<div class="column" :style="{transform: `translateY(${-lineHeight*index2}PX)`}">
			<div class="num" v-for="(item, index) in arr2" :key="index">{{ item }}</div>
		</div>
		<div class="column" :style="{transform: `translateY(${-lineHeight*index1}PX)`}">
			<div class="num" v-for="(item, index) in arr1" :key="index">{{ item }}</div>
		</div>
	</div>
</template>
<script>
function clearTimer() {
	if(!window.clockTimer){ window.clockTimer = []}
	window.clockTimer.map(item=>{
		clearInterval(item)
	})
}
export default {
	name: 'scrollClock',
	props: {
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			lineHeight: 60,
			arr1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			index1: 0,
			arr2: [0, 1, 2, 3, 4, 5],
			index2: 0,
			// 分
			arr3: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			index3: 0,
			arr4: [0, 1, 2, 3, 4, 5],
			index4: 0,
			// 时
			arr5: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			index5: 0,
			arr6: [0, 1, 2],
			index6: 0
		}
	},
	watch: {
		index5(newVal) {
			if (this.index6 === 2 && newVal === 4) {
				for (let i = 1; i < 7; i++) {
					this[`index${i}`] = 0
				}
			}
		}
	},
	methods: {
		getTime() {
			const date = new Date()
			let hour = this.bu0(date.getHours())
			let minute = this.bu0(date.getMinutes())
			let second = this.bu0(date.getSeconds())
			this.index1 = +second[1]
			this.index2 = +second[0]
			this.index3 = +minute[1]
			this.index4 = +minute[0]
			this.index5 = +hour[1]
			this.index6 = +hour[0]
			this.turnSecond(this.arr1.length)			
		},
		turnSecond(length) {
			clearTimer()
			const clockTimerId = setInterval(() => {
				this.getTime()
			}, 1000)
			window.clockTimer.push(clockTimerId)
		},
		bu0(num) {
			let str
			if (num < 10) str = `0${num}`
			else str = `${num}`
			return str.split('')
		}
	},
	mounted() {
		this.getTime()
	}
}
</script>
<style scoped lang="less">
.scrollClock {
	text-align: center;
	background: #ffffff;
	font-size: 50PX;
	height: 60PX;
	font-weight: bolder;
	letter-spacing: 3px;
	display: flex;
	justify-content: center;
	overflow: hidden;
	font-family: 'Nums' !important;
	font-weight: bold;
	color: #333;
}
.column {
	transition: transform 300ms;
}
.num {
	height: 60PX;
}
</style>