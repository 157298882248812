<template>
  <div class="cube-radio" :class="_containerClass" :data-pos="position">
    <label class="cube-radio-wrap">
      <input
        class="cube-radio-input"
        type="radio"
        :disabled="option.disabled"
        v-model="radioValue"
        :value="computedOption.value"
      />
      <span class="cube-radio-ui">
        <i class="radio-bg"></i>
        <i class="cubeic-ok"></i>
      </span>
      <slot>
        <span class="cube-radio-label">{{ computedOption.label }}</span>
      </slot>
    </label>
  </div>
</template>

<script type="text/ecmascript-6">
const COMPONENT_NAME = 'cube-radio'
const EVENT_INPUT = 'input'

export default {
  name: COMPONENT_NAME,
  inject: {
    radioGroup: {
      default: null
    }
  },
  props: {
    value: [String, Number],
    option: {
      type: [String, Object],
      required: true
    },
    position: {
      type: String,
      default: 'left'
    },
    hollowStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radioValue: this.value
    }
  },
  created() {
    const radioGroup = this.radioGroup
    if (radioGroup && radioGroup.radioValue !== void 0) {
      this.radioValue = radioGroup.radioValue
      this._cancelWatchGroup = this.$watch(
        () => {
          return radioGroup.radioValue
        },
        newValue => {
          this.radioValue = newValue
        }
      )
    }
  },
  beforeDestroy() {
    this._cancelWatchGroup && this._cancelWatchGroup()
    this._cancelWatchGroup = null
  },
  watch: {
    value(newV) {
      this.radioValue = newV
    },
    radioValue(newV) {
      if (typeof this.value === 'number') {
        newV = Number(newV)
      }
      this.$emit(EVENT_INPUT, newV)
      if (this.radioGroup) {
        this.radioGroup.radioValue = newV
      }
    }
  },
  computed: {
    computedOption() {
      const option = this.option
      if (typeof option === 'string') {
        return {
          value: option,
          label: option
        }
      }
      return option
    },
    _containerClass() {
      const option = this.computedOption
      return {
        'cube-radio-hollow': this.hollowStyle,
        'cube-radio_selected': this.radioValue === option.value,
        'cube-radio_disabled': option.disabled
      }
    },
    _wrapClass() {
      let parent = this.$parent
      if (!(parent.horizontal || parent.$props.colNum > 1)) {
        return 'border-bottom-1px'
      }
    }
  }
}
</script>
<style lang="stylus" rel="stylesheet/stylus">
  @require "../../common/stylus/variable.styl"
  @require "../../common/stylus/mixin.styl"
  $ui-width = 1.42em
  .cube-radio
    position: relative
    text-align: left
    font-size: 100%
    color: $radio-color
    &[data-pos="right"]
      .cube-radio-ui
        margin-right: 0
        position: absolute
        right: 0
      .cube-radio-label
        margin-right: $ui-width
  .cube-radio-wrap
    position: relative
    display: flex
    align-items: center
    box-sizing: border-box
    width: 100%
    height: 100%
    word-break: break-word
    word-wrap: break-word
  .cube-radio-input
    z-index: 1
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
  .cube-radio-ui
    position: relative
    width 20px
    height 20px
    margin-right: $ui-width - 1em
    line-height: 1
    color: transparent
    background-color: transparent
    &::before, i
      transition: all .2s
      width 20px
      height 20px
    &::before
      color: $radio-icon-color
      display: inline-block
      transform: scale(1.4)
      width 20px
      height 20px
    .radio-bg
      box-sizing border-box
      position: absolute
      overflow: hidden
      width 40px
      height 40px
      top 0
      left 0
      margin-top -10px
      margin-left -10px
      border-radius: 50%
      border 1px solid #d2d2d2
      transform: scale(.5)
    .cubeic-ok
      position: absolute
      top 0
      left 0
      width: 100%
      height: 100%
      border-radius: 50%
      display flex
      align-items center
      justify-content center
      font-size 18px
      transform: scale(.4)
  .cube-radio_selected
    .radio-bg
      background none
      border none
    .cubeic-ok
      transform: scale(1)
      color #fff
      background-color $color-def-1

</style>
