<template>
<cube-page bgColor="#f8f8f8"> 
	<div slot="content" class="pageMain">
		<div class="logoCon">
			<img :src="banner" width="100%"/>
		</div>
		<div class="codeBox">
			<div class="timeClock">
				<TimeClock></TimeClock>
			</div>
			<div class="title tLine">
				{{lange == 'CN' ? '我的登记回执' : 'Registration Receipt'}}
			</div>
			<div class="qrCode">
				<canvas id="qrCode" :style="{'width': width + 'px', 'height': height +'px'}"></canvas>
			</div>
			<div class="codeNo">{{userInfo.barCode}}</div>
		</div>
		<div class="infoLink">
			{{lange == 'CN' ? '已填报的信息' : 'Registration Info'}}
			<span @click="goInfo">{{lange == 'CN' ? '查看' : 'View'}}</span>
		</div>
		<div class="infoCon" v-if="lange == 'CN'">
			您好！欢迎预登记中国教玩具之都品牌发展大会暨第三届中国教玩具之都国际博览会，展会于2024年5月21-22日在桥下教玩具国际城举办！期待您的到来！
			<p>温馨提示：</p>
			<p>1．凭该页面于现场办证处换取入场证件。</p>
			<p>2．该页面截图无效。</p>
			<p>3．换取证件后请在现场工作人员的引导下，有序入馆参观。</p>
		</div>
		<div class="infoCon" v-else>
			Hello! Welcome to pre register for the China Education Toy Capital Brand Development Conference and the 3rd China Education Toy Capital International Expo, which will be held from May 21 to 22, 2024 at Qiaoxia Education Toy International City! Looking forward to your arrival!
		</div>
		<div class="shearBtnLine">
			<cube-button class="shearBtn" @click="shearBtn">{{lange == 'CN' ? '邀请好友一起逛展':'Share'}}</cube-button>
		</div>
		<uiPage @close="showUserInfo = false" :show="showUserInfo" :title="lange == 'CN' ? '登记信息':'Registration Info'">
			<userInfo ref="userPage" :userInfo="userInfo" />
		</uiPage>
		<transition name="fade" mode="out-in">
			<div class="shareBg" v-if="showShare">
				<img src="../assets/images/share.svg">
				<div class="shareBtn" @click="showShare = false">知道啦</div>
			</div>
		</transition>
	</div>
</cube-page>
</template>
<script>
import bannerCn from '@/assets/images/banner1Cn.jpg'
import bannerEn from '@/assets/images/banner1En.jpg'
import TimeClock from '@/components/TimeClock/index'
import QRCode from 'qrcode'
import uiPage from '@/components/ui-page'
import userInfo from './userInfo'
export default {
	components: { TimeClock, uiPage, userInfo },
	data() {
		return {
			form: {}, 
			width: 180,
			height: 180,
			lange: window.localStorage.getItem('LANGE') || 'CN',
			showUserInfo: false,
			showShare: false
		}
	},
	computed: {
		banner() {
			return this.lange == 'CN' ? bannerCn : bannerEn
		},
		userInfo() {
			let userInfo = window.localStorage.getItem('TOY_USER_INFO')
			let phone = window.localStorage.getItem('TOY_PHONE')
			if (userInfo) {
				return JSON.parse(userInfo)
			} else {
				return {}
			}
		}
	},
	created(){
		console.log(this.userInfo)
	},
	mounted() {
		let width = this.width, height = this.height
		QRCode.toCanvas(
			document.getElementById('qrCode'),
			this.userInfo.code,
			{ width, height, toSJISFunc: QRCode.toSJIS, margin: 2 },
			error => {}
		)
	},
	methods: {
		goInfo() {
			this.showUserInfo = true
		},
		shearBtn() {
			this.showShare = true
		}
	}
}
</script>
<style lang="less" scoped>
.shareBg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;
	img {
		width: 70%;
		position: absolute;
		right: 10px;
		top: 5px;
	}
	.shareBtn {
		border: 2px solid #fff;
		border-radius: 1000px;
		width: 120px;
		height: 40px;
		position: absolute;
		bottom: 30%;
		left: 50%;
		transform: translateX(-50%);
		font-size: 17px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.qrCode {
	
}
.codeBox {
	background-color: #fff;
	margin: 15px;
	padding: 15px;
	border-radius: 10px;
	color: #333;
	.title {
		font-weight: bold;
		color: #333;
		text-align: center;
		font-size: 18px;
		padding-top: 20px;
	}
	.qrCode {
		width: 180PX;
		height: 180PX;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		margin: 10px auto;
	}
	.codeNo {
		text-align: center;
		padding: 10px 0;
	}
}
.infoLink {
	margin: 15px;
	padding: 15px;
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	color: #333;
	span {
		font-weight: bold;
	}
}
.infoCon {
	margin: 15px;
	padding: 15px;
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	color: #666;
	line-height: 1.6;
}
.shearBtnLine {
	margin: 15px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	color: #666;
	line-height: 1.6;
	.shearBtn {
		background: linear-gradient(to bottom, #fbec85, #f9e244);
		color: #222;
		border-radius: 100px;
		font-weight: bold;
		font-size: 17px;
		color: #a07807;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px 20px;
		box-shadow: 0px 10px 10px rgba(250, 193, 0, 0.2);
	}
}
</style>
