<template>
<cube-page title="首页" hasClass="hasBg" :bgImg="langeText.bg" bgColor="#fac100"> 
	<div slot="content" class="pageMain">
		<div class="topBg">
			<img src="../assets/images/top.png" width="100%" v-if="lange == 'CN'"/>
			<img src="../assets/images/topEn.png" width="100%" v-else/>
		</div>
		<div class="bottomBg">
			<img src="../assets/images/bottom.png" width="100%"/>
		</div>
		<div class="changeLange fixIcon" @click="changeLange">
			<i class="iconfont icon-zhongyingwen" v-if="lange == 'CN'"></i>
			<i class="iconfont icon-zhongyingwen2zhongwen" v-else></i>
		</div>
		<div class="share fixIcon" @click="shareWx">
			<i class="iconfont icon-fenxiangfangshi"></i>
			{{langeText.share}}
		</div>
		<div class="homeBtn">
			<div class="visitor btn" @click="goPage('/login')">
				{{langeText.btn1}}
			</div>
			<div class="staff btn" @click="goInfo">
				{{langeText.btn2}}
			</div>
		</div>
		<div class="clause" @click="checked = !checked" v-if="lange == 'CN'">
			<div class="clauseCon">
				<div class="tips">
					请先阅读并接受隐私条款
					<i class="iconfont icon-qipao"/>
				</div>
				<em class="checkIcon" :class="{active: checked}">
					<i class="iconfont icon-duihao" v-if="checked"/>
				</em>	
				<a>接受并阅读</a> 
				<span @click.stop="lookBook">《隐私条款》</span>
			</div>
		</div>
		<visitorBook @confirm="bookSub" @close="showBook = false" :show="showBook"/>
		<transition name="fade" mode="out-in">
			<div class="shareBg" v-if="showShare">
				<img src="../assets/images/share.svg">
				<div class="shareBtn" @click="showShare = false">知道啦</div>
			</div>
		</transition>
	</div>
</cube-page>
</template>
<script>
import { cn, en } from '@/utils/language'
import { queryByPhone, queryByEmail } from '@/api'
import visitorBook from './visitorBook'

import wx from "weixin-js-sdk"
export default {
	components: {
		visitorBook
	},
	data() {
		return {
			checked: false,
			lange: window.localStorage.getItem('LANGE') || 'CN',
			showBook: false,
			shareImg: require('@/assets/images/share.png'),
			showShare: false
		}
	},
	computed: {
		langeText() {
			if (this.lange == 'CN') {
				return cn
			} else {
				return en
			}
		}
	},
	created() {
		  wx.ready(() => {
				console.log(wx)
			if (wx.updateAppMessageShareData) { 
				wx.updateAppMessageShareData({
					//分享给朋友及分享到QQ
					title: '2024教玩具展-观众登记',
					desc: '2024年5月21-22日桥下教玩具国际城期待您的到来!',
					link: `http://os.global-eservice.com`,
					imgUrl: this.shareImg,
					trigger: () => {
						console.log('点击分享')
					},
					success: () => {
						console.log('分享成功')
					},
					cancel: () => {
						console.log('分享取消')
					},
					fail: () => {
						console.log('分享失败')
					},
				})
			} else {
				console.log(this.goodsList.item_name)
				wx.onMenuShareAppMessage({
					//分享给朋友及分享到QQ
					title: '2024教玩具展-观众登记',
					desc: '2024年5月21-22日桥下教玩具国际城期待您的到来!',
					link: `http://os.global-eservice.com`,
					imgUrl: this.shareImg,
					trigger: () => {
						console.log('点击分享')
					},
					success: () => {
						console.log('分享成功')
					},
					cancel: () => {
						console.log('分享取消')
					},
					fail: () => {
						console.log('分享失败')
					},
				})
			}
			if (wx.updateTimelineShareData) {
				wx.updateTimelineShareData({
					//分享到朋友圈及分享到QQ空间
					title: '2024教玩具展-观众登记',
					desc: '2024年5月21-22日桥下教玩具国际城期待您的到来!',
					link: `http://os.global-eservice.com`,
					imgUrl: this.shareImg,
					trigger: () => {
						console.log('点击分享')
					},
					success: () => {
						console.log('分享成功')
					},
					cancel: () => {
						console.log('分享取消')
					},
					fail: () => {
						console.log('分享失败')
					},
				})
			} else {
				wx.onMenuShareTimeline({
					//分享到朋友圈及分享到QQ空间
					title: '2024教玩具展-观众登记',
					desc: '2024年5月21-22日桥下教玩具国际城期待您的到来!',
					link: `http://os.global-eservice.com`,
					imgUrl: this.shareImg,
					trigger: () => {
						console.log('点击分享')
					},
					success: () => {
						console.log('分享成功')
					},
					cancel: () => {
						console.log('分享取消')
					},
					fail: () => {
						console.log('分享失败')
					},
				})
			}
		})
	},
	methods: {
		shareWx() {
			this.showShare = true
		},
		goInfo() {
			if (this.lange == 'CN' && !this.checked) {
				this.$createToast({ txt: '请同意隐私协议', icon: 'icon-error', time: 2000 }).show()
				return 
			}
			if (this.lange == 'CN') {
				const userPhone = window.localStorage.getItem('TOY_PHONE')
				if (userPhone) {
					queryByPhone({phone: userPhone}).then(res=> {
						window.localStorage.setItem('TOY_USER_INFO', JSON.stringify(res.result))
						this.$router.push('/confirmation')
					}).catch(err=> {
						this.$router.push('/login')
					})
				} else {
					this.$router.push('/login')
				}
			} else {
				const email = window.localStorage.getItem('TOY_EMAIL')
				if (email) {
					queryByEmail({email: email}).then(res=> {
						window.localStorage.setItem('TOY_USER_INFO', JSON.stringify(res.result))
						this.$router.push('/confirmation')
					}).catch(err=> {
						this.$router.push('/login')
					})
				} else {
					this.$router.push('/login')
				}
			}
		},
		bookSub() {
			this.showBook = false
			this.checked = true
		},
		lookBook() {
			this.showBook = true
		},
		goPage(url) {
			if (this.lange == 'CN' && !this.checked) {
				this.$createToast({ txt: '请同意隐私协议', icon: 'icon-error', time: 2000 }).show()
				return 
			}
			this.$router.push(url)
		},
		changeLange() {
			const lange = window.localStorage.getItem('LANGE') || 'CN'
			if (lange == 'CN') {
				window.localStorage.setItem('LANGE', 'EN')
			} else {
				window.localStorage.setItem('LANGE', 'CN')
			}
			this.lange = window.localStorage.getItem('LANGE') || 'CN'
		}
	}
}
</script>
<style lang="less" scoped>
.shareBg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;
	img {
		width: 70%;
		position: absolute;
		right: 10px;
		top: 5px;
	}
	.shareBtn {
		border: 2px solid #fff;
		border-radius: 1000px;
		width: 120px;
		height: 40px;
		position: absolute;
		bottom: 30%;
		left: 50%;
		transform: translateX(-50%);
		font-size: 17px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.topBg {
	position: absolute;
	top: 12%;
}
.bottomBg {
	position: absolute;
	bottom: 0;
	left: 0;
}
.homeBtn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	box-sizing: border-box;
	padding: 0 40px;
}
.fixIcon {
	position: absolute;
	right: 20px;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	width: 46px;
	height: 46px;
	color: #4e2a8c;
	i {
		font-size: 21px;
		color: #4e2a8c;
	}
}
.clause {
	position: absolute;
	bottom: 20px;

	transform: translateX(-50%);
	left: 50%;
	justify-content: center;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	width: 100%;
	.clauseCon {
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 100px;
		padding: 5px;
		display: flex;
		position: relative;
	}
	span {
		color: #fbec85;
	}
	a {
		color: #fff;
	}
	.checkIcon {
		width: 14px;
		height: 14px;
		border: 1px solid #fff;
		border-radius: 2px;
		margin-right: 4px;
		margin-left: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 300ms;
		&.active {
			background: #f9e244;
			border: 1px solid #f9e244;
			color: #fff;
		}
	}
	.tips {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		transform: translateY(-150%);
		background-color: #fff;
		border-radius: 10px;
		padding:10px;
		color: #a07807;
		display: flex;
		justify-content: center;
		box-shadow: 0px 10px 10px rgba(250, 193, 0, 0.5);
		i {
			position: absolute;
			bottom: -14px;
			left: 20px;
			color: #fff;
			font-size: 24px;
		}
	}
}
.changeLange {
	top: 20px;
}
.share {
	top: 75px;
	display: flex;
	flex-direction: column;
	font-size: 12px;
	i {
		font-size: 17px;
	}
}
.btn {
	background: linear-gradient(to bottom, #fbec85, #f9e244);
	color: #222;
	border-radius: 100px;
	font-weight: bold;
	font-size: 17px;
	color: #a07807;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	box-shadow: 0px 10px 10px rgba(250, 193, 0, 0.5);
	i {
		font-size: 22px;
		margin-right: 10px;
	}
}
</style>
