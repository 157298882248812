export const countryEn = [
    {
        "text": "China",
        "value": "China"
    },
    {
        "text": "Andorra",
        "value": "Andorra"
    },
    {
        "text": "UnitedArabEmirates",
        "value": "UnitedArabEmirates"
    },
    {
        "text": "Afghanistan",
        "value": "Afghanistan"
    },
    {
        "text": "AntiguaandBarbuda",
        "value": "AntiguaandBarbuda"
    },
    {
        "text": "Anguilla",
        "value": "Anguilla"
    },
    {
        "text": "Albania",
        "value": "Albania"
    },
    {
        "text": "Armenia",
        "value": "Armenia"
    },
    {
        "text": "Ascension",
        "value": "Ascension"
    },
    {
        "text": "Angola",
        "value": "Angola"
    },
    {
        "text": "Argentina",
        "value": "Argentina"
    },
    {
        "text": "Austria",
        "value": "Austria"
    },
    {
        "text": "Australia",
        "value": "Australia"
    },
    {
        "text": "Azerbaijan",
        "value": "Azerbaijan"
    },
    {
        "text": "Barbados",
        "value": "Barbados"
    },
    {
        "text": "Bangladesh",
        "value": "Bangladesh"
    },
    {
        "text": "Belgium",
        "value": "Belgium"
    },
    {
        "text": "Burkina-faso",
        "value": "Burkina-faso"
    },
    {
        "text": "Bulgaria",
        "value": "Bulgaria"
    },
    {
        "text": "Bahrain",
        "value": "Bahrain"
    },
    {
        "text": "Burundi",
        "value": "Burundi"
    },
    {
        "text": "Benin",
        "value": "Benin"
    },
    {
        "text": "Palestine",
        "value": "Palestine"
    },
    {
        "text": "BermudaIs",
        "value": "BermudaIs"
    },
    {
        "text": "Brunei",
        "value": "Brunei"
    },
    {
        "text": "Bolivia",
        "value": "Bolivia"
    },
    {
        "text": "Brazil",
        "value": "Brazil"
    },
    {
        "text": "Bahamas",
        "value": "Bahamas"
    },
    {
        "text": "Botswana",
        "value": "Botswana"
    },
    {
        "text": "Belarus",
        "value": "Belarus"
    },
    {
        "text": "Belize",
        "value": "Belize"
    },
    {
        "text": "Canada",
        "value": "Canada"
    },
    {
        "text": "CaymanIs",
        "value": "CaymanIs"
    },
    {
        "text": "CentralAfricanRepublic",
        "value": "CentralAfricanRepublic"
    },
    {
        "text": "Congo",
        "value": "Congo"
    },
    {
        "text": "Switzerland",
        "value": "Switzerland"
    },
    {
        "text": "CookIs.",
        "value": "CookIs."
    },
    {
        "text": "Chile",
        "value": "Chile"
    },
    {
        "text": "Cameroon",
        "value": "Cameroon"
    },
    {
        "text": "Colombia",
        "value": "Colombia"
    },
    {
        "text": "CostaRica",
        "value": "CostaRica"
    },
    {
        "text": "Czech",
        "value": "Czech"
    },
    {
        "text": "Cuba",
        "value": "Cuba"
    },
    {
        "text": "Cyprus",
        "value": "Cyprus"
    },
    {
        "text": "CzechRepublic",
        "value": "CzechRepublic"
    },
    {
        "text": "Germany",
        "value": "Germany"
    },
    {
        "text": "Djibouti",
        "value": "Djibouti"
    },
    {
        "text": "Denmark",
        "value": "Denmark"
    },
    {
        "text": "DominicaRep.",
        "value": "DominicaRep."
    },
    {
        "text": "Algeria",
        "value": "Algeria"
    },
    {
        "text": "Ecuador",
        "value": "Ecuador"
    },
    {
        "text": "Estonia",
        "value": "Estonia"
    },
    {
        "text": "Egypt",
        "value": "Egypt"
    },
    {
        "text": "Spain",
        "value": "Spain"
    },
    {
        "text": "Ethiopia",
        "value": "Ethiopia"
    },
    {
        "text": "Finland",
        "value": "Finland"
    },
    {
        "text": "Fiji",
        "value": "Fiji"
    },
    {
        "text": "France",
        "value": "France"
    },
    {
        "text": "Gabon",
        "value": "Gabon"
    },
    {
        "text": "UnitedKiongdom",
        "value": "UnitedKiongdom"
    },
    {
        "text": "Grenada",
        "value": "Grenada"
    },
    {
        "text": "Georgia",
        "value": "Georgia"
    },
    {
        "text": "FrenchGuiana",
        "value": "FrenchGuiana"
    },
    {
        "text": "Ghana",
        "value": "Ghana"
    },
    {
        "text": "Gibraltar",
        "value": "Gibraltar"
    },
    {
        "text": "Gambia",
        "value": "Gambia"
    },
    {
        "text": "Guinea",
        "value": "Guinea"
    },
    {
        "text": "Greece",
        "value": "Greece"
    },
    {
        "text": "Guatemala",
        "value": "Guatemala"
    },
    {
        "text": "Guam",
        "value": "Guam"
    },
    {
        "text": "Guyana",
        "value": "Guyana"
    },
    {
        "text": "Hongkong",
        "value": "Hongkong"
    },
    {
        "text": "Honduras",
        "value": "Honduras"
    },
    {
        "text": "Haiti",
        "value": "Haiti"
    },
    {
        "text": "Hungary",
        "value": "Hungary"
    },
    {
        "text": "Indonesia",
        "value": "Indonesia"
    },
    {
        "text": "Ireland",
        "value": "Ireland"
    },
    {
        "text": "Israel",
        "value": "Israel"
    },
    {
        "text": "India",
        "value": "India"
    },
    {
        "text": "Iraq",
        "value": "Iraq"
    },
    {
        "text": "Iran",
        "value": "Iran"
    },
    {
        "text": "Iceland",
        "value": "Iceland"
    },
    {
        "text": "Italy",
        "value": "Italy"
    },
    {
        "text": "IvoryCoast",
        "value": "IvoryCoast"
    },
    {
        "text": "Jamaica",
        "value": "Jamaica"
    },
    {
        "text": "Jordan",
        "value": "Jordan"
    },
    {
        "text": "Japan",
        "value": "Japan"
    },
    {
        "text": "Kenya",
        "value": "Kenya"
    },
    {
        "text": "Kyrgyzstan",
        "value": "Kyrgyzstan"
    },
    {
        "text": "Kampuchea(Cambodia)",
        "value": "Kampuchea(Cambodia)"
    },
    {
        "text": "NorthKorea",
        "value": "NorthKorea"
    },
    {
        "text": "Korea",
        "value": "Korea"
    },
    {
        "text": "RepublicofIvoryCoast",
        "value": "RepublicofIvoryCoast"
    },
    {
        "text": "Kuwait",
        "value": "Kuwait"
    },
    {
        "text": "Kazakstan",
        "value": "Kazakstan"
    },
    {
        "text": "Laos",
        "value": "Laos"
    },
    {
        "text": "Lebanon",
        "value": "Lebanon"
    },
    {
        "text": "St.Lucia",
        "value": "St.Lucia"
    },
    {
        "text": "Liechtenstein",
        "value": "Liechtenstein"
    },
    {
        "text": "SriLanka",
        "value": "SriLanka"
    },
    {
        "text": "Liberia",
        "value": "Liberia"
    },
    {
        "text": "Lesotho",
        "value": "Lesotho"
    },
    {
        "text": "Lithuania",
        "value": "Lithuania"
    },
    {
        "text": "Luxembourg",
        "value": "Luxembourg"
    },
    {
        "text": "Latvia",
        "value": "Latvia"
    },
    {
        "text": "Libya",
        "value": "Libya"
    },
    {
        "text": "Morocco",
        "value": "Morocco"
    },
    {
        "text": "Monaco",
        "value": "Monaco"
    },
    {
        "text": "Moldova,Republicof",
        "value": "Moldova,Republicof"
    },
    {
        "text": "Madagascar",
        "value": "Madagascar"
    },
    {
        "text": "Mali",
        "value": "Mali"
    },
    {
        "text": "Burma",
        "value": "Burma"
    },
    {
        "text": "Mongolia",
        "value": "Mongolia"
    },
    {
        "text": "Macao",
        "value": "Macao"
    },
    {
        "text": "MontserratIs",
        "value": "MontserratIs"
    },
    {
        "text": "Malta",
        "value": "Malta"
    },
    {
        "text": "MarianaIs",
        "value": "MarianaIs"
    },
    {
        "text": "Martinique",
        "value": "Martinique"
    },
    {
        "text": "Mauritius",
        "value": "Mauritius"
    },
    {
        "text": "Maldives",
        "value": "Maldives"
    },
    {
        "text": "Malawi",
        "value": "Malawi"
    },
    {
        "text": "Mexico",
        "value": "Mexico"
    },
    {
        "text": "Malaysia",
        "value": "Malaysia"
    },
    {
        "text": "Mozambique",
        "value": "Mozambique"
    },
    {
        "text": "Namibia",
        "value": "Namibia"
    },
    {
        "text": "Niger",
        "value": "Niger"
    },
    {
        "text": "Nigeria",
        "value": "Nigeria"
    },
    {
        "text": "Nicaragua",
        "value": "Nicaragua"
    },
    {
        "text": "Netherlands",
        "value": "Netherlands"
    },
    {
        "text": "Norway",
        "value": "Norway"
    },
    {
        "text": "Nepal",
        "value": "Nepal"
    },
    {
        "text": "NetheriandsAntilles",
        "value": "NetheriandsAntilles"
    },
    {
        "text": "Nauru",
        "value": "Nauru"
    },
    {
        "text": "NewZealand",
        "value": "NewZealand"
    },
    {
        "text": "Oman",
        "value": "Oman"
    },
    {
        "text": "Panama",
        "value": "Panama"
    },
    {
        "text": "Peru",
        "value": "Peru"
    },
    {
        "text": "FrenchPolynesia",
        "value": "FrenchPolynesia"
    },
    {
        "text": "PapuaNewCuinea",
        "value": "PapuaNewCuinea"
    },
    {
        "text": "Philippines",
        "value": "Philippines"
    },
    {
        "text": "Pakistan",
        "value": "Pakistan"
    },
    {
        "text": "Poland",
        "value": "Poland"
    },
    {
        "text": "PuertoRico",
        "value": "PuertoRico"
    },
    {
        "text": "Portugal",
        "value": "Portugal"
    },
    {
        "text": "Paraguay",
        "value": "Paraguay"
    },
    {
        "text": "Qatar",
        "value": "Qatar"
    },
    {
        "text": "Reunion",
        "value": "Reunion"
    },
    {
        "text": "Romania",
        "value": "Romania"
    },
    {
        "text": "Russia",
        "value": "Russia"
    },
    {
        "text": "SaudiArabia",
        "value": "SaudiArabia"
    },
    {
        "text": "SolomonIs",
        "value": "SolomonIs"
    },
    {
        "text": "Seychelles",
        "value": "Seychelles"
    },
    {
        "text": "Sudan",
        "value": "Sudan"
    },
    {
        "text": "Sweden",
        "value": "Sweden"
    },
    {
        "text": "Singapore",
        "value": "Singapore"
    },
    {
        "text": "Slovenia",
        "value": "Slovenia"
    },
    {
        "text": "Slovakia",
        "value": "Slovakia"
    },
    {
        "text": "SierraLeone",
        "value": "SierraLeone"
    },
    {
        "text": "SanMarino",
        "value": "SanMarino"
    },
    {
        "text": "SamoaEastern",
        "value": "SamoaEastern"
    },
    {
        "text": "SanMarino",
        "value": "SanMarino"
    },
    {
        "text": "Senegal",
        "value": "Senegal"
    },
    {
        "text": "Somali",
        "value": "Somali"
    },
    {
        "text": "Suriname",
        "value": "Suriname"
    },
    {
        "text": "SaoTomeandPrincipe",
        "value": "SaoTomeandPrincipe"
    },
    {
        "text": "EISalvador",
        "value": "EISalvador"
    },
    {
        "text": "Syria",
        "value": "Syria"
    },
    {
        "text": "Swaziland",
        "value": "Swaziland"
    },
    {
        "text": "Chad",
        "value": "Chad"
    },
    {
        "text": "Togo",
        "value": "Togo"
    },
    {
        "text": "Thailand",
        "value": "Thailand"
    },
    {
        "text": "Tajikstan",
        "value": "Tajikstan"
    },
    {
        "text": "Turkmenistan",
        "value": "Turkmenistan"
    },
    {
        "text": "Tunisia",
        "value": "Tunisia"
    },
    {
        "text": "Tonga",
        "value": "Tonga"
    },
    {
        "text": "Turkey",
        "value": "Turkey"
    },
    {
        "text": "TrinidadandTobago",
        "value": "TrinidadandTobago"
    },
    {
        "text": "Taiwan",
        "value": "Taiwan"
    },
    {
        "text": "Tanzania",
        "value": "Tanzania"
    },
    {
        "text": "Ukraine",
        "value": "Ukraine"
    },
    {
        "text": "Uganda",
        "value": "Uganda"
    },
    {
        "text": "UnitedStatesofAmerica",
        "value": "UnitedStatesofAmerica"
    },
    {
        "text": "Uruguay",
        "value": "Uruguay"
    },
    {
        "text": "Uzbekistan",
        "value": "Uzbekistan"
    },
    {
        "text": "SaintVincent",
        "value": "SaintVincent"
    },
    {
        "text": "Venezuela",
        "value": "Venezuela"
    },
    {
        "text": "Vietnam",
        "value": "Vietnam"
    },
    {
        "text": "Yemen",
        "value": "Yemen"
    },
    {
        "text": "Yugoslavia",
        "value": "Yugoslavia"
    },
    {
        "text": "SouthAfrica",
        "value": "SouthAfrica"
    },
    {
        "text": "Zambia",
        "value": "Zambia"
    },
    {
        "text": "Zaire",
        "value": "Zaire"
    },
    {
        "text": "Zimbabwe",
        "value": "Zimbabwe"
    }
]