<template>
<cube-page title="员工认证" listenBack="1" @interceptBack="goBack"> 
	<div slot="content" class="pageMain">
		<div class="logoCon">
			<img :src="banner" width="100%"/>
		</div>
		<div class="form">
			<div class="formLine" v-if="lange == 'CN'">
				<div class="input inputBorder">
					<cube-input v-model="form.phone" type="number" clearable :placeholder="lange == 'CN' ? '请输入手机号码': 'Enter your phone'">
						<i slot="prepend" class="iconfont icon-shouji inputIcon"/>
						<div slot="append" class="sendSms" @click="sendSms">
							<span v-if="showCode">{{second}}</span>
							<span v-else>{{lange == 'CN' ? '发送':'Send'}}</span>
						</div>
					</cube-input>
				</div>
			</div>
			<div class="formLine" v-else>
				<div class="input inputBorder">
					<cube-input v-model="form.email" clearable placeholder="Emall address">
						<i slot="prepend" class="iconfont icon-youxiang inputIcon"/>
					</cube-input>
				</div>
			</div>
			<div class="formLine" v-if="lange == 'CN'">
				<div class="input inputBorder">
					<cube-input type="number" v-model="form.code" clearable :placeholder="lange == 'CN' ? '请输入验证码' : 'Verification Code' ">
						<i slot="prepend" class="iconfont icon-yanzhengma inputIcon"/>
					</cube-input>
				</div>
			</div>
			<div class="btnLine">
				<cube-button :disabled="disabled" class="submitBtn" @click="nextStep">{{lange == 'CN' ? '下一步':'Next step'}}</cube-button>
			</div>
		</div>
	</div>
</cube-page>
</template>
<script>
import bannerCn from '@/assets/images/banner1Cn.jpg'
import bannerEn from '@/assets/images/banner1En.jpg'
import { sendCode, checkCode, queryByPhone} from '@/api'
import { isPhone } from '@/utils'
export default {
	data() {
		return {
			form: {
				phone: '',
				code: '',
				email: ''
			}, 
			person: {},
			second: 120,
			showCode: false,
			lange: window.localStorage.getItem('LANGE') || 'CN'
		}
	},
	computed: {
		banner() {
			return this.lange == 'CN' ? bannerCn : bannerEn
		},
		disabled() {
			let flg
			if (this.lange == 'CN') {
				if (this.form.phone  && this.form.code) {
					flg = false
				} else {
					flg = true
				}
			} else {
				if (this.form.email) {
					flg = false
				} else {
					flg = true
				}
			}
			return flg
		}
	},
	created() {
		
	},
	methods: {
		sendSms() {
			if (!this.form.phone) {
				this.$createToast({ txt: '请输入手机号码', icon: 'iconfont icon-error', time: 2000 }).show()
				return
			}
			if (!isPhone(this.form.phone)) {
				this.$createToast({ txt: '手机号码不正确', icon: 'iconfont icon-error', time: 2000 }).show()
				return
			}
			this.$clearTimer('timer')
			sendCode({phone: this.form.phone}).then(res=> {
				this.showCode = true
				const timerId = setInterval(()=> {
					this.second--
					if (this.second <= 0) {
						this.second = 120
						this.$clearTimer('timer')
						this.showCode = false
					}
				}, 1000)
				window['timer'].push(timerId)
			})
		},
		goBack() {
			this.$router.push('/')
		},
		nextStep() {
			if (this.lange == 'CN') {
				if (!isPhone(this.form.phone)) {
					this.$createToast({ txt: '手机号码不正确', icon: 'iconfont icon-error', time: 2000 }).show()
					return
				}
				checkCode(this.form).then(res=> {
					window.localStorage.setItem('USER_PHONE', this.form.phone)
					
					queryByPhone({phone: this.form.phone}).then(res=> {
						window.localStorage.setItem('TOY_USER_INFO', JSON.stringify(res.result))
						this.$router.push('/confirmation')
					}).catch(err=> {
						this.$router.push('/regist')
					})
					
					// this.$router.push('/regist')
				})
			} else {
				window.localStorage.setItem('USER_EMAIL', this.form.email)
				this.$router.push('/regist')
			}
			
		}
	}
}
</script>
<style lang="less" scoped>
	.inputBorder {
		border: 1px solid #ccc;
		border-radius: 100px;
		padding: 0 17px;
		position: relative;
	}
.sendSms {
	color: #333;
	font-size: 15px;
	margin-left: 10px;
	position: absolute;
	top: 0;
	right: 0;
	background-color: #999;
	width: 80px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0 100px 100px 0;
	color: #fff;
	font-weight: 700 !important;
}
.formTitle {
	font-size: 15px;
	font-weight: bold;
	color: #333;
	margin-top: 15px;
	padding-bottom: 5px;
}
.formLine {
	display: flex;
	align-items: center;
	padding: 3px 0;
	margin: 5px 0;
	.label {
		width: 100px;
		color: #333;
	}
	.input {
		flex: 1;
	}
}

.btnLine {
	padding-top: 20px;
	.submitBtn {
		border-radius: 1000px;
		font-weight: bold;
		box-shadow: 0px 10px 10px rgba(250, 193, 0, 0.2);
	}
}
.sendBtn {
	color: #999;
	b {
		font-weight: bold;
		color: #333;
	}
}
.logoCon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 50px;
}
.inputIcon {
	color: #333;
	font-size: 18px;
	margin-right: 4px;
}
.form {
	padding: 0 40px;
}
</style>
