import request from '@/utils/request'
export function add(data) {
  return request({
    url: `/visitor/visitor/add`,
    method: 'post',
		data
  })
}

export function queryByPhone(data) {
  return request({
    url: `/visitor/visitor/queryByPhone?phone=${data.phone}`,
    method: 'get',
  })
}

export function queryByEmail(data) {
  return request({
    url: `/visitor/visitor/queryByEmail?email=${data.email}`,
    method: 'get',
  })
}


export function sendCode(data) {
  return request({
    url: `/visitor/visitor/sendCode`,
    method: 'post',
		data
  })
}

export function checkCode(data) {
  return request({
    url: `/visitor/visitor/checkCode`,
    method: 'post',
		data
  })
}

export function upLoadUser(data) {
  return request({
		baseURL: 'https://os.global-eservice.com',
    url: `/api/common/uploadUser`,
    method: 'post',
		data
  })
}
