import {
  Button,
  Input,
  Textarea,
  Select,
	Loading,
  Picker,
  CascadePicker,
  DatePicker,
  TimePicker,
	Slide,
	Switch,
	IndexList,
  BetterScroll,
	PrimaryLoading,
	Dialog,
	Scroll,
	Toast,
  createAPI,
	SegmentPicker
} from './module'
import { processComponentName } from './common/helpers/util'

const components = [
  Button,
  Input,
  Textarea,
  Select,
	Loading,
  Picker,
  CascadePicker,
  DatePicker,
  TimePicker,
	IndexList,
	Slide,
	PrimaryLoading,
	Switch,
	Toast,
	Dialog,
	Scroll,
	SegmentPicker
]

function install(Vue) {
  if (install.installed) {
    return
  }
  install.installed = true
  components.forEach((Component) => {
    Component.install(Vue)
  })
}
const Cube = {
  version: '1.0.0',
  install,
  BScroll: BetterScroll,
  createAPI
}
components.forEach((Component) => {
  const name = processComponentName(Component, {
    firstUpperCase: true
  })
  Cube[name] = Component
})
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
}
export default Cube
