<template>
<div class="cube-page" :style="style" :class="['hasClass']">
	<header class="header" :class="{ bLine: showBorder }" v-if="showHead">
		<i @click="back" v-if="showBack" class="iconfont icon-arrow-left"/>
		<div class="title">{{title}}</div>
		<a class="rightBtn" :class="{active: rightStatus}" @click="rightBtnHander" v-if="rightBtn">{{rightBtn}}</a>
	</header>
	<div class="wrapper" :class="{notHead: !showHead, notScroll: notScroll }">
		<main class="content">
			<slot name="content"></slot>
		</main>
	</div>
</div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
		hasClass: {
			type: String,
      default: ''
		},
    showHead: {
      type: Boolean,
      default: false
    },
		showBorder: {
		  type: Boolean,
		  default: false
		},
		rightIcon: {
		  type: String,
		  default: ''
		},
		rightBtn: {
		  type: String,
		  default: ''
		},
		rightStatus: {
		  type: Boolean,
		  default: false
		},
		listenBack: {
		  type: [Number, String],
		  default: null
		},
		notScroll: {
			type: Boolean,
			default: false
		},
		showBack: {
			type: Boolean,
			default: true
		},
		bgColor: {
			type: String,
			default: '#fff'
		},
		bgImg: {
			type: String,
			default: ''
		}
  },
	data() {
		return {
			keyword: '',
			openSeach: false,
			bgTop: require('@/assets/images/bgTop.png')
		}
	},
	computed: {
		seachText() {
			if (this.keyword) return 'Done'
			return 'Cancel'
		},
		style() {
			if (this.bgImg) {
				return {
					backgroundColor: this.bgColor,
					backgroundImage: `url(${this.bgTop})`,
				}
			} else {
				return {
					backgroundImage: 'linear-gradient(to bottom, #f5f4f6, #e9edf7);'
				}
			}
		}
	},
  methods: {
    back() {
			if(this.listenBack) {
				return this.$emit('interceptBack')
			}
			if (window.history.length > 1) {
				this.$router.back()
			} else {
				this.$router.replace('/')
			}
    },
		rightHander() {
      this.$emit('rightHander')			
		},
		rightBtnHander() {
			this.$emit('rightBtnHander')		
		},
		changeKeyword(e) {
			if (!e) {
				this.$emit('cancel')
			}
		}
  }
}
</script>

<style lang="less" scoped>
.cube-page {
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	overflow: hidden;
	background-size: 100% auto;
	background-position: top left;
	background-repeat: no-repeat;
	div {
		box-sizing: border-box;
	}
	.header {
		position: relative;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background-color: #fff;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		z-index: 5;
		color: #000000;
		.title {
			font-size: 17px;
		}
		.icon-arrow-left {
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 14px;
			font-size: 18px;
		}
		.rightIcon {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 14px;
			font-size: 21px;
		}
		.rightBtn {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 14px;
			font-size: 15px;
			color: #BBBBBB;
			&.active {
				color: #CAFF41;
			}
		}
	}
	>.wrapper {
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: unset;
		height: calc(100% - 48px)
	}
	.bootomBorder {
		&:before {
			content: '';
			width: 200%;
			height: 1px;
			background-color: #2c2c2c;
			position: absolute;
			bottom: 0px;
			left: 0;
			transform: scaleY(0.5);
		}
	}
}

.whiteBg {
	background-color: #fff;
}
.wrapper.notHead {
  height: 100%;
}
.wrapper.notScroll {
  overflow-y: hidden;
	overflow-x: hidden;
}
</style>
