export function addZero(obj) {
	if (obj < 10) return '0' + '' + obj
	else return obj
}

export function isName(value) {
	const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
	return reg.test(value)
}

export function isPhone(str) {
	const reg = /^1\d{10}$/
	return reg.test(str)
}

export function isIdCard(str) {
	const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
	return reg.test(str)
}

export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}"
	let date
	if (typeof time === "object") {
		date = time
	} else {
		if (typeof time === "string" && /^[0-9]+$/.test(time)) {
			time = parseInt(time);
		}
		if (typeof time === "number" && time.toString().length === 10) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	};
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key];
		if (key === "a") {
			return ["日", "一", "二", "三", "四", "五", "六"][value]
		}
		if (result.length > 0 && value < 10) {
			value = "0" + value
		}
		return value || 0
	});
	return time_str
}

export function uuid(length = 32) {
  const num = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
  let str = "";
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length))
  }
  return str
}



