import { render, staticRenderFns } from "./visitorBook.vue?vue&type=template&id=5f33f190&scoped=true"
import script from "./visitorBook.vue?vue&type=script&lang=js"
export * from "./visitorBook.vue?vue&type=script&lang=js"
import style0 from "./visitorBook.vue?vue&type=style&index=0&id=5f33f190&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f33f190",
  null
  
)

export default component.exports