export const countryCn = [
    {
        "text": "中国",
        "value": "China"
    },
    {
        "text": "安道尔共和国",
        "value": "Andorra"
    },
    {
        "text": "阿拉伯联合酋长国",
        "value": "UnitedArabEmirates"
    },
    {
        "text": "阿富汗",
        "value": "Afghanistan"
    },
    {
        "text": "安提瓜和巴布达",
        "value": "AntiguaandBarbuda"
    },
    {
        "text": "安圭拉岛",
        "value": "Anguilla"
    },
    {
        "text": "阿尔巴尼亚",
        "value": "Albania"
    },
    {
        "text": "阿美尼亚",
        "value": "Armenia"
    },
    {
        "text": "阿森松",
        "value": "Ascension"
    },
    {
        "text": "安哥拉",
        "value": "Angola"
    },
    {
        "text": "阿根廷",
        "value": "Argentina"
    },
    {
        "text": "奥地利",
        "value": "Austria"
    },
    {
        "text": "澳大利亚",
        "value": "Australia"
    },
    {
        "text": "阿塞拜疆",
        "value": "Azerbaijan"
    },
    {
        "text": "巴巴多斯",
        "value": "Barbados"
    },
    {
        "text": "孟加拉国",
        "value": "Bangladesh"
    },
    {
        "text": "比利时",
        "value": "Belgium"
    },
    {
        "text": "布基纳法索",
        "value": "Burkina-faso"
    },
    {
        "text": "保加利亚",
        "value": "Bulgaria"
    },
    {
        "text": "巴林",
        "value": "Bahrain"
    },
    {
        "text": "布隆迪",
        "value": "Burundi"
    },
    {
        "text": "贝宁",
        "value": "Benin"
    },
    {
        "text": "巴勒斯坦",
        "value": "Palestine"
    },
    {
        "text": "百慕大群岛",
        "value": "BermudaIs"
    },
    {
        "text": "文莱",
        "value": "Brunei"
    },
    {
        "text": "玻利维亚",
        "value": "Bolivia"
    },
    {
        "text": "巴西",
        "value": "Brazil"
    },
    {
        "text": "巴哈马",
        "value": "Bahamas"
    },
    {
        "text": "博茨瓦纳",
        "value": "Botswana"
    },
    {
        "text": "白俄罗斯",
        "value": "Belarus"
    },
    {
        "text": "伯利兹",
        "value": "Belize"
    },
    {
        "text": "加拿大",
        "value": "Canada"
    },
    {
        "text": "开曼群岛",
        "value": "CaymanIs"
    },
    {
        "text": "中非共和国",
        "value": "CentralAfricanRepublic"
    },
    {
        "text": "刚果",
        "value": "Congo"
    },
    {
        "text": "瑞士",
        "value": "Switzerland"
    },
    {
        "text": "库克群岛",
        "value": "CookIs."
    },
    {
        "text": "智利",
        "value": "Chile"
    },
    {
        "text": "喀麦隆",
        "value": "Cameroon"
    },
    {
        "text": "哥伦比亚",
        "value": "Colombia"
    },
    {
        "text": "哥斯达黎加",
        "value": "CostaRica"
    },
    {
        "text": "捷克",
        "value": "Czech"
    },
    {
        "text": "古巴",
        "value": "Cuba"
    },
    {
        "text": "塞浦路斯",
        "value": "Cyprus"
    },
    {
        "text": "捷克",
        "value": "CzechRepublic"
    },
    {
        "text": "德国",
        "value": "Germany"
    },
    {
        "text": "吉布提",
        "value": "Djibouti"
    },
    {
        "text": "丹麦",
        "value": "Denmark"
    },
    {
        "text": "多米尼加共和国",
        "value": "DominicaRep."
    },
    {
        "text": "阿尔及利亚",
        "value": "Algeria"
    },
    {
        "text": "厄瓜多尔",
        "value": "Ecuador"
    },
    {
        "text": "爱沙尼亚",
        "value": "Estonia"
    },
    {
        "text": "埃及",
        "value": "Egypt"
    },
    {
        "text": "西班牙",
        "value": "Spain"
    },
    {
        "text": "埃塞俄比亚",
        "value": "Ethiopia"
    },
    {
        "text": "芬兰",
        "value": "Finland"
    },
    {
        "text": "斐济",
        "value": "Fiji"
    },
    {
        "text": "法国",
        "value": "France"
    },
    {
        "text": "加蓬",
        "value": "Gabon"
    },
    {
        "text": "英国",
        "value": "UnitedKiongdom"
    },
    {
        "text": "格林纳达",
        "value": "Grenada"
    },
    {
        "text": "格鲁吉亚",
        "value": "Georgia"
    },
    {
        "text": "法属圭亚那",
        "value": "FrenchGuiana"
    },
    {
        "text": "加纳",
        "value": "Ghana"
    },
    {
        "text": "直布罗陀",
        "value": "Gibraltar"
    },
    {
        "text": "冈比亚",
        "value": "Gambia"
    },
    {
        "text": "几内亚",
        "value": "Guinea"
    },
    {
        "text": "希腊",
        "value": "Greece"
    },
    {
        "text": "危地马拉",
        "value": "Guatemala"
    },
    {
        "text": "关岛",
        "value": "Guam"
    },
    {
        "text": "圭亚那",
        "value": "Guyana"
    },
    {
        "text": "香港(中国)",
        "value": "Hongkong"
    },
    {
        "text": "洪都拉斯",
        "value": "Honduras"
    },
    {
        "text": "海地",
        "value": "Haiti"
    },
    {
        "text": "匈牙利",
        "value": "Hungary"
    },
    {
        "text": "印度尼西亚",
        "value": "Indonesia"
    },
    {
        "text": "爱尔兰",
        "value": "Ireland"
    },
    {
        "text": "以色列",
        "value": "Israel"
    },
    {
        "text": "印度",
        "value": "India"
    },
    {
        "text": "伊拉克",
        "value": "Iraq"
    },
    {
        "text": "伊朗",
        "value": "Iran"
    },
    {
        "text": "冰岛",
        "value": "Iceland"
    },
    {
        "text": "意大利",
        "value": "Italy"
    },
    {
        "text": "科特迪瓦",
        "value": "IvoryCoast"
    },
    {
        "text": "牙买加",
        "value": "Jamaica"
    },
    {
        "text": "约旦",
        "value": "Jordan"
    },
    {
        "text": "日本",
        "value": "Japan"
    },
    {
        "text": "肯尼亚",
        "value": "Kenya"
    },
    {
        "text": "吉尔吉斯坦",
        "value": "Kyrgyzstan"
    },
    {
        "text": "柬埔寨",
        "value": "Kampuchea(Cambodia)"
    },
    {
        "text": "朝鲜",
        "value": "NorthKorea"
    },
    {
        "text": "韩国",
        "value": "Korea"
    },
    {
        "text": "科特迪瓦共和国",
        "value": "RepublicofIvoryCoast"
    },
    {
        "text": "科威特",
        "value": "Kuwait"
    },
    {
        "text": "哈萨克斯坦",
        "value": "Kazakstan"
    },
    {
        "text": "老挝",
        "value": "Laos"
    },
    {
        "text": "黎巴嫩",
        "value": "Lebanon"
    },
    {
        "text": "圣卢西亚",
        "value": "St.Lucia"
    },
    {
        "text": "列支敦士登",
        "value": "Liechtenstein"
    },
    {
        "text": "斯里兰卡",
        "value": "SriLanka"
    },
    {
        "text": "利比里亚",
        "value": "Liberia"
    },
    {
        "text": "莱索托",
        "value": "Lesotho"
    },
    {
        "text": "立陶宛",
        "value": "Lithuania"
    },
    {
        "text": "卢森堡",
        "value": "Luxembourg"
    },
    {
        "text": "拉脱维亚",
        "value": "Latvia"
    },
    {
        "text": "利比亚",
        "value": "Libya"
    },
    {
        "text": "摩洛哥",
        "value": "Morocco"
    },
    {
        "text": "摩纳哥",
        "value": "Monaco"
    },
    {
        "text": "摩尔多瓦",
        "value": "Moldova,Republicof"
    },
    {
        "text": "马达加斯加",
        "value": "Madagascar"
    },
    {
        "text": "马里",
        "value": "Mali"
    },
    {
        "text": "缅甸",
        "value": "Burma"
    },
    {
        "text": "蒙古",
        "value": "Mongolia"
    },
    {
        "text": "澳门（中国）",
        "value": "Macao"
    },
    {
        "text": "蒙特塞拉特岛",
        "value": "MontserratIs"
    },
    {
        "text": "马耳他",
        "value": "Malta"
    },
    {
        "text": "马里亚那群岛",
        "value": "MarianaIs"
    },
    {
        "text": "马提尼克",
        "value": "Martinique"
    },
    {
        "text": "毛里求斯",
        "value": "Mauritius"
    },
    {
        "text": "马尔代夫",
        "value": "Maldives"
    },
    {
        "text": "马拉维",
        "value": "Malawi"
    },
    {
        "text": "墨西哥",
        "value": "Mexico"
    },
    {
        "text": "马来西亚",
        "value": "Malaysia"
    },
    {
        "text": "莫桑比克",
        "value": "Mozambique"
    },
    {
        "text": "纳米比亚",
        "value": "Namibia"
    },
    {
        "text": "尼日尔",
        "value": "Niger"
    },
    {
        "text": "尼日利亚",
        "value": "Nigeria"
    },
    {
        "text": "尼加拉瓜",
        "value": "Nicaragua"
    },
    {
        "text": "荷兰",
        "value": "Netherlands"
    },
    {
        "text": "挪威",
        "value": "Norway"
    },
    {
        "text": "尼泊尔",
        "value": "Nepal"
    },
    {
        "text": "荷属安的列斯",
        "value": "NetheriandsAntilles"
    },
    {
        "text": "瑙鲁",
        "value": "Nauru"
    },
    {
        "text": "新西兰",
        "value": "NewZealand"
    },
    {
        "text": "阿曼",
        "value": "Oman"
    },
    {
        "text": "巴拿马",
        "value": "Panama"
    },
    {
        "text": "秘鲁",
        "value": "Peru"
    },
    {
        "text": "法属玻利尼西亚",
        "value": "FrenchPolynesia"
    },
    {
        "text": "巴布亚新几内亚",
        "value": "PapuaNewCuinea"
    },
    {
        "text": "菲律宾",
        "value": "Philippines"
    },
    {
        "text": "巴基斯坦",
        "value": "Pakistan"
    },
    {
        "text": "波兰",
        "value": "Poland"
    },
    {
        "text": "波多黎各",
        "value": "PuertoRico"
    },
    {
        "text": "葡萄牙",
        "value": "Portugal"
    },
    {
        "text": "巴拉圭",
        "value": "Paraguay"
    },
    {
        "text": "卡塔尔",
        "value": "Qatar"
    },
    {
        "text": "留尼旺",
        "value": "Reunion"
    },
    {
        "text": "罗马尼亚",
        "value": "Romania"
    },
    {
        "text": "俄罗斯",
        "value": "Russia"
    },
    {
        "text": "沙特阿拉伯",
        "value": "SaudiArabia"
    },
    {
        "text": "所罗门群岛",
        "value": "SolomonIs"
    },
    {
        "text": "塞舌尔",
        "value": "Seychelles"
    },
    {
        "text": "苏丹",
        "value": "Sudan"
    },
    {
        "text": "瑞典",
        "value": "Sweden"
    },
    {
        "text": "新加坡",
        "value": "Singapore"
    },
    {
        "text": "斯洛文尼亚",
        "value": "Slovenia"
    },
    {
        "text": "斯洛伐克",
        "value": "Slovakia"
    },
    {
        "text": "塞拉利昂",
        "value": "SierraLeone"
    },
    {
        "text": "圣马力诺",
        "value": "SanMarino"
    },
    {
        "text": "东萨摩亚(美)",
        "value": "SamoaEastern"
    },
    {
        "text": "西萨摩亚",
        "value": "SanMarino"
    },
    {
        "text": "塞内加尔",
        "value": "Senegal"
    },
    {
        "text": "索马里",
        "value": "Somali"
    },
    {
        "text": "苏里南",
        "value": "Suriname"
    },
    {
        "text": "圣多美和普林西比",
        "value": "SaoTomeandPrincipe"
    },
    {
        "text": "萨尔瓦多",
        "value": "EISalvador"
    },
    {
        "text": "叙利亚",
        "value": "Syria"
    },
    {
        "text": "斯威士兰",
        "value": "Swaziland"
    },
    {
        "text": "乍得",
        "value": "Chad"
    },
    {
        "text": "多哥",
        "value": "Togo"
    },
    {
        "text": "泰国",
        "value": "Thailand"
    },
    {
        "text": "塔吉克斯坦",
        "value": "Tajikstan"
    },
    {
        "text": "土库曼斯坦",
        "value": "Turkmenistan"
    },
    {
        "text": "突尼斯",
        "value": "Tunisia"
    },
    {
        "text": "汤加",
        "value": "Tonga"
    },
    {
        "text": "土耳其",
        "value": "Turkey"
    },
    {
        "text": "特立尼达和多巴哥",
        "value": "TrinidadandTobago"
    },
    {
        "text": "台湾（中国）",
        "value": "Taiwan"
    },
    {
        "text": "坦桑尼亚",
        "value": "Tanzania"
    },
    {
        "text": "乌克兰",
        "value": "Ukraine"
    },
    {
        "text": "乌干达",
        "value": "Uganda"
    },
    {
        "text": "美国",
        "value": "UnitedStatesofAmerica"
    },
    {
        "text": "乌拉圭",
        "value": "Uruguay"
    },
    {
        "text": "乌兹别克斯坦",
        "value": "Uzbekistan"
    },
    {
        "text": "圣文森特岛",
        "value": "SaintVincent"
    },
    {
        "text": "委内瑞拉",
        "value": "Venezuela"
    },
    {
        "text": "越南",
        "value": "Vietnam"
    },
    {
        "text": "也门",
        "value": "Yemen"
    },
    {
        "text": "南斯拉夫",
        "value": "Yugoslavia"
    },
    {
        "text": "南非",
        "value": "SouthAfrica"
    },
    {
        "text": "赞比亚",
        "value": "Zambia"
    },
    {
        "text": "扎伊尔",
        "value": "Zaire"
    },
    {
        "text": "津巴布韦",
        "value": "Zimbabwe"
    }
]